<template>
	<div>
		<!-- Location is set -->
		<div v-if="isLocationSet === true">
			<ion-item>
				<div tabindex="0"></div>
				{{formattedAddress}}
			</ion-item>
			<!-- Action -->
			<ion-item>
				<div tabindex="0"></div>
				<ion-button @click.prevent="isLocationSet = false">
					Change Location
				</ion-button>
			</ion-item>	
		</div>
		<!-- Location is not set -->
		<div v-if="isLocationSet === false">
			<!-- Venue -->
			<div v-if="profileType == 'venue'">
				<PlaceFormWrapper :store="store" :user-id="userId" :place-name="name" />
			</div>
			<!-- All other profiles -->
			<div v-else>
				<!-- Business -->
				<div v-if="profileType == 'business'">
					<!-- Show Chips -->
					<div v-if="showChips === true">
						<div>
							<h3>Does your business have a physical address?</h3>
							<p>This will be displayed to the public</p>
						</div>
						<ion-chip color="light" @click="physicalAddress = false">
							<ion-label>
								No
							</ion-label>
						</ion-chip>
						<ion-chip color="light" @click="physicalAddress = true">
							<ion-label>
								Yes
							</ion-label>
						</ion-chip>							
					</div>
					<!-- Business with physical address -->
					<div v-if="physicalAddress === true">
						<PlaceFormWrapper :store="store" :user-id="userId" :place-name="name" />
					</div>
					<!-- Business with out physical address -->
					<div v-if="physicalAddress === false">
						<ProfileLocation :store="store" @component-child-data="getData" />
					</div>
				</div>
				<!-- All others -->
				<div v-else>
					<ProfileLocation :store="store" @component-child-data="getData" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import { IonItem, IonButton, IonChip, IonLabel } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import PlaceFormWrapper from '../components/PlaceFormWrapper.vue'
import ProfileLocation from '../components/ProfileLocation.vue'

export default defineComponent( {
	components: { IonItem, IonButton, PlaceFormWrapper, IonChip, IonLabel, ProfileLocation },
	props: ['store'],
	name: 'ProfileLocationWrapper',
	setup(props) {
		const profileType = ref(null);
		const location = ref(null);
		const isLocationSet = ref(false);
		const formattedAddress = ref(null);
		const userId = ref(0);
		const name = ref(null);
		const physicalAddress = ref(null);
		const showChips = ref(false);

		onMounted(() => {
			if(props.store.authUser) {
				profileType.value = props.store.authUser.profile_type
				location.value = props.store.authUser.location
				userId.value = props.store.authUser.id
				name.value = props.store.authUser.name
			}
			checkLocation()
			if(isLocationSet.value === true) {
				formattedAddress.value = location.value.formatted_address
			}
			if(profileType.value == 'business') {
				showChips.value = true
			}
		})

		function checkLocation() {
			if(location.value === null) {
				isLocationSet.value = false
			}
			if(location.value != null) {
				isLocationSet.value = true
			}
		}

		return {
			profileType, location, checkLocation, isLocationSet, formattedAddress, userId, name, physicalAddress, showChips
		}
	},
	methods: {
		// Used on all other components 
		getData: function(e) {
			if(e.dataType == 'user') {
				this.$emit('component-data', e)
			}
		}
	},
	watch: {
		physicalAddress: function() {
			if(this.physicalAddress != null) {
				this.showChips = false
			}
		}
	}
});
</script>