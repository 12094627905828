<template>
	<div>
		<!-- Check if auth user is set -->
		<div v-if="store.authUser">
			<!-- Results -->
			<div v-if="results.length > 0">
				<ion-item v-for="result in results" :key="result" button detail color="light" @click.prevent="confirmLocation(result)">
					<div tabindex="0"></div><!-- bug fix for safari 14 -->
					{{result.formatted_address}}
				</ion-item>
			</div>
			<!-- Input -->
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-label position="stacked">Zip Code</ion-label>
				<ion-input v-model="zipCode" type="text"></ion-input>
			</ion-item>
			<!-- Actions -->
			<div v-if="zipCode != null">
				<ion-item v-if="zipCode.length >= 3 && zipCode.length <= 5">
					<div tabindex="0"></div><!-- bug fix for safari 14 -->
					<ion-button color="light" @click.prevent="verifyZipCode()">Submit</ion-button>
				</ion-item>
			</div>						
		</div>
	</div>
</template>

<script>
import { IonItem, IonButton, IonInput, IonLabel, loadingController, toastController } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import apiClient from '../services/api';

export default defineComponent( {
	components: { IonItem, IonButton, IonInput, IonLabel },
	props: ['store'],
	name: 'ProfileLocation',
	setup(props) {
		const zipCode = ref(null);
		const timeout = { default: 6000 }
		const results = ref([]);
		const city = ref(null);
		const state = ref(null);
		const stateFull = ref(null);
		const country = ref(null);
		const countryFull = ref(null);
		const county = ref(null);
		const formattedAddress = ref(null);
		const placeId = ref(null);
		const lat = ref(null);
		const lng = ref(null);		
		const confirmedLocation = ref(false);

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 2000,
				message: 'Your profile has been updated.',
				position: 'bottom',
				buttons: [{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		function verifyZipCode () {
			presentLoading()
			// Send auth token
			apiClient.get('/api/zip-code/results/' + zipCode.value, 
			{
				headers: {
					'Authorization':  `Bearer ${props.store.authToken}`
				}
			}).then(response => {
				console.log(response)
				if(response.status === 200) {
					results.value = response.data.results
					// Could not find zip code
					if(results.value.length < 1) {
						//
					}
					if(results.value.length == 1) {
						//
					}
					if(results.value.length > 1) {
						//
					} 
				}
			}).catch(error => {
				console.log(error)  
			});
		}

		function confirmLocation(location) {
			if(location.address_components.length > 0) {
				for(var i=0; i<location.address_components.length; i++) {
					if(location.address_components[i].types.length > 0) {
						for(var x = 0; x<location.address_components[i].types.length; x++) {
							// City
							if( (location.address_components[i].types[x] == 'locality') || (location.address_components[i].types[x] == 'neighborhood') || (location.address_components[i].types[x] == 'sublocality_level_1') || (location.address_components[i].types[x] == 'sublocality') || (location.address_components[i].types[x] == 'postal_town') ) {
								city.value = location.address_components[i].long_name
							}
							// Country
							if(location.address_components[i].types[x] == 'country') {
								country.value = location.address_components[i].short_name
								countryFull.value = location.address_components[i].long_name
							} 
							// County
							if(location.address_components[i].types[x] == 'administrative_area_level_2') {
								county.value = location.address_components[i].long_name
							} 
							// State
							if(location.address_components[i].types[x] == 'administrative_area_level_1') {
								stateFull.value = location.address_components[i].long_name
								state.value = location.address_components[i].short_name
							}          
						}
					}
				}
			}

			formattedAddress.value = location.formatted_address
			placeId.value = location.place_id
			lat.value = location.geometry.location.lat
			lng.value = location.geometry.location.lng

			if(city.value != '' && state.value != '' && country.value != ''  && zipCode.value != '' && placeId.value != '' && lat.value != '' && lng.value != '') {
				confirmedLocation.value = true
			} else {
				confirmedLocation.value = false
			}
		}

		function storeLocation() {
			// post location
			// Send auth token
			presentLoading()
			apiClient.post('/api/user-location', 
				{
					city: city.value,
					country: country.value,
					country_full: countryFull.value,
					county: county.value,
					state: state.value,
					state_full: stateFull.value,
					zip_code: zipCode.value,
					place_id: placeId.value,
					lat: lat.value,
					lng: lng.value,
					formatted_address: formattedAddress.value            
				},
				{
				headers: {
					'Authorization':  `Bearer ${props.store.authToken}`
				}
			}).then(response => {
				console.log(response)
				localStorage.setItem('authUser', JSON.stringify(response.data.user));
				this.dataUp(response.data.user);
				//store.commit('setAuthUser', response.data.user);
				openToast()
			}).catch(error => {
				console.log(error)  
				// credentials didn't match
			});
		}

		return { zipCode, verifyZipCode, presentLoading, results, confirmLocation, city, state, stateFull, county, country, countryFull, lat, lng, placeId, confirmedLocation, formattedAddress, storeLocation }
	},
	methods: {
		dataUp: function(user) {
			var componentData = {
				dataType: 'user',
				data: user
			}

			this.$emit('component-child-data', componentData)
		}
	},
	watch: {
		confirmedLocation: function() {
			if(this.confirmedLocation === true) {
				this.storeLocation()
			}
		}
	}
});
</script>